import React from "react";
import {loadingSpin} from "./routingContainer";
import {Protected} from "./routingWrappers";

const Main = React.lazy(() => import("../components/mainPage"));

export const MainPage = () => <Protected loadingIndicator={loadingSpin}>
    <Main/>
</Protected>

const AnnouncementTableContainer = React.lazy(() => import("../announcement/announcementModalForm/announcementListContainer"));

export const AnnouncementPage = () => <Protected loadingIndicator={loadingSpin}
                                                 allowedForRoles={['prepare:announcements', 'review:announcements']}>
    <AnnouncementTableContainer/>
</Protected>

const UserTableContainer = React.lazy(() => import("../user/userListContainer"));

export const UserPage = () => <Protected loadingIndicator={loadingSpin}
                                         allowedForRoles={['prepare:announcements', 'review:announcements']}>
    <UserTableContainer/>
</Protected>


const CategoryTableContainer = React.lazy(() => import("../category/categoryListContainer"));

export const CategoryPage = () => <Protected loadingIndicator={loadingSpin}
                                             allowedForRoles={['prepare:announcements', 'review:announcements']}>
    <CategoryTableContainer/>
</Protected>