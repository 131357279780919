import {
    BranchesOutlined,
    FileTextOutlined,
    LoginOutlined,
    LogoutOutlined,
    MenuOutlined,
    TeamOutlined,
    UserOutlined
} from '@ant-design/icons';
import {Avatar, Col, ConfigProvider, Drawer, Row, Tooltip} from 'antd';
import {Header} from "antd/lib/layout/layout";
import React, {ReactElement, useEffect, useState} from 'react';
import styles from './headerComponent.module.less';
import {AsyncButton, useCurrentRoutingPath} from "digimuth-components/dist";
import {User} from '@auth0/auth0-react';
import Logo from "../../../assets/Logo.svg"
import {useNavigate} from "react-router-dom";
import {RoutingPaths} from "../../routing/routingContainer";
import useCurrentUserRoles from "../../../hooks/useCurrentUserRoles";
import isAuthorizedForCms from "../../../helpers/authorizationHelper";
import { mainColor } from '../../../App';

interface HeaderComponentProps {
    onLogoutPressed: () => Promise<any>,
    onLoginPressed: () => Promise<any>,
    userData?: User | null;
}

interface mobileMenuItem {
    name: string,
    navLink: string,
    icon: string | ReactElement;
}

export default function HeaderComponent(props: HeaderComponentProps) {
    const navigate = useNavigate();
    const userRoles = useCurrentUserRoles();
    const route = useCurrentRoutingPath(RoutingPaths)?.route || "";

    const [showMenu, setShowMenu] = useState<boolean>(false);
    const [showTooltip, setShowTooltip] = useState<boolean>(window.innerWidth <= 768 && window.innerWidth > 425);

    const mobileMenuItems: mobileMenuItem[] = [{
        name: "Powiadomienia",
        navLink: RoutingPaths.announcementTable.route,
        icon: <FileTextOutlined/>
    }, ...(userRoles?.includes("prepare:announcements") && userRoles?.includes("review:announcements")) ? [{
        name: "Użytkownicy",
        navLink: RoutingPaths.userTable.route,
        icon: <TeamOutlined/>
    }, {
        name: "Kategorie",
        navLink: RoutingPaths.categoryTable.route,
        icon: <BranchesOutlined/>
    }] : []];

    useEffect(() => {
        const resize = () => {
            const shouldShowTooltip = window.innerWidth <= 768 && window.innerWidth > 425;
            if (shouldShowTooltip !== showTooltip) setShowTooltip(shouldShowTooltip);
        }
        window.addEventListener("resize", resize);
        return () => window.removeEventListener("resize", resize);
    }, [])

    if (props.userData) {
        return <Header className={styles.exHeader}>
            <img
                src={Logo}
                alt={"logo"}
                style={{marginRight: "auto", cursor: "pointer"}}
                onClick={() => navigate(RoutingPaths.mainPage.route)}
            />
            <span className={styles.usernameText}>
                {props.userData.name}
            </span>
            <ConfigProvider theme={{
                token: {
                    colorBgSpotlight: mainColor,
                    paddingXS: 20
                }
            }}>
                <Tooltip title={showTooltip ? props.userData.name : ""}>
                    <Avatar
                        className={styles.userAvatar}
                        size={40}
                        icon={<UserOutlined/>}
                    />
                </Tooltip>
            </ConfigProvider>
            <AsyncButton
                className={styles.logOutButton}
                type="text"
                onClick={props.onLogoutPressed}
            >
                Wyloguj się <LogoutOutlined style={{fontSize: 24}}/>
            </AsyncButton>
            <MenuOutlined
                className={styles.burgerMenu}
                onClick={() => setShowMenu(true)}
            />
            <LogoutOutlined
                onClick={props.onLogoutPressed}
                className={styles.logoutIcon}
                style={{fontSize: 24}}
            />
            <ConfigProvider theme={{token: {colorIcon: "white"}}}>
                <Drawer
                    open={showMenu}
                    width={"100%"}
                    headerStyle={{backgroundColor: "#5F5C63"}}
                    onClose={() => setShowMenu(false)}
                    footer={null}
                    bodyStyle={{display: 'flex', justifyContent: 'center', marginTop: "100px"}}
                    extra={<img
                        src={Logo}
                        alt={"logo"}
                        style={{marginRight: "auto", marginTop: "10px"}}
                        onClick={() => navigate(RoutingPaths.mainPage.route)}
                    />}
                >
                    <div style={{height: "fit-content"}}>
                        {isAuthorizedForCms(userRoles) && mobileMenuItems.map((item: mobileMenuItem, index: number) =>
                            <div
                                key={index} style={{display: "flex", marginBottom: "25px"}}
                                onClick={() => {
                                    navigate(item.navLink);
                                    setShowMenu(false);
                                }}
                            >
                                <div style={{
                                    fontSize: "16px",
                                    color: route === item.navLink ? mainColor : "#000000"
                                }}>
                                    {item.icon}
                                </div>
                                <div
                                    style={{
                                        marginLeft: "10px",
                                        fontSize: "18px",
                                        color: route === item.navLink ? mainColor : "#000000"
                                    }}>
                                    {item.name}
                                </div>
                            </div>)}
                    </div>
                    <div style={{position: "absolute", bottom: "50px"}}>
                        <div>{props.userData.name}</div>
                        <div onClick={props.onLogoutPressed}
                             style={{
                                 display: "flex",
                                 justifyContent: "center",
                                 alignItems: "center",
                                 marginTop: "30px"
                             }}
                        >
                            <LogoutOutlined style={{marginRight: "10px", fontSize: "18px"}}/>
                            <div>Wyloguj się</div>
                        </div>
                    </div>
                </Drawer>
            </ConfigProvider>
        </Header>;
    }

    return <Header className={styles.exHeader}>
        <Row>
            <div style={{flex: 1}}/>
            <Col>
                <AsyncButton
                    type="text"
                    onClick={props.onLoginPressed}
                    icon={<LoginOutlined/>}
                >
                    Login
                </AsyncButton>
            </Col>
        </Row>
    </Header>;
}
