import {BranchesOutlined, FileTextOutlined, TableOutlined, TeamOutlined} from "@ant-design/icons";
import {ConfigProvider, Menu} from "antd";
import {NavLink} from "react-router-dom";
import {RoutingPaths} from "../../routing/routingContainer";
import styles from "./siderMenu.module.less"
import {useCurrentRoutingPath} from "digimuth-components/dist";
import useCurrentUserRoles from "../../../hooks/useCurrentUserRoles";
import { mainColor } from "../../../App";

export default function SiderMenu() {
    const route = useCurrentRoutingPath(RoutingPaths)?.route || "";
    const userRoles = useCurrentUserRoles();

    const siderDefaultTheme = {
        token: {
            borderRadius: 0
        },
        components: {
            Menu: {
                itemBg: "#FFFFFF",
                itemSelectedColor: mainColor,
                itemSelectedBg: "#FFFFFF",
            }
        }
    }

    return <ConfigProvider theme={siderDefaultTheme}>
        <div className={styles.sideMenuWrapper}>
            <Menu selectedKeys={[route]} mode="inline">
                <Menu.Item key={RoutingPaths.announcementTable.route} icon={<FileTextOutlined/>}>
                    <NavLink to={RoutingPaths.announcementTable.route}>
                        Powiadomienia
                    </NavLink>
                </Menu.Item>
                {(userRoles?.includes("prepare:announcements") && userRoles?.includes("review:announcements"))
                    && <Menu.Item key={RoutingPaths.userTable.route} icon={<TeamOutlined/>}>
                        <NavLink to={RoutingPaths.userTable.route}>
                            Użytkownicy
                        </NavLink>
                    </Menu.Item>
                }
                {(userRoles?.includes("prepare:announcements") && userRoles?.includes("review:announcements"))
                    && <Menu.Item key={RoutingPaths.categoryTable.route} icon={<BranchesOutlined />}>
                        <NavLink to={RoutingPaths.categoryTable.route}>
                            Kategorie
                        </NavLink>
                    </Menu.Item>
                }
            </Menu>
        </div>
    </ConfigProvider>;
}
