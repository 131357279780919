import "./App.module.scss";
import {Provider as InversifyProvider} from 'inversify-react';
import {Provider, useDispatch} from 'react-redux'
import {store} from './redux/store';
import RoutingContainer, {RoutingPaths} from "./pages/routing/routingContainer";
import {BrowserRouter, matchRoutes, createRoutesFromChildren, useLocation, useNavigationType} from "react-router-dom";
import ApiService from "./services/apiService";
import SiderMenu from './pages/components/siderMenu/siderMenu';
import HeaderContainer from './pages/components/header/headerContainer';
import FooterContainer from './pages/components/footer/footerContainer';
import {CrudBreadcrumb, CrudLayout} from 'digimuth-components/dist';
import {Container} from 'inversify';
import {ConfigProvider} from "antd";
import {Auth0Provider, useAuth0} from '@auth0/auth0-react';
import React, {useEffect, useState} from 'react';
import jwt_decode from "jwt-decode";
import {setCurrentUser} from './redux/applicationUserSlice';
import useCurrentUserRoles from "./hooks/useCurrentUserRoles";
import isAuthorizedForCms from "./helpers/authorizationHelper";
import * as Sentry from "@sentry/react";

Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [
        Sentry.reactRouterV6BrowserTracingIntegration({
            useEffect: React.useEffect,
            useLocation,
            useNavigationType,
            createRoutesFromChildren,
            matchRoutes
        }),
        Sentry.replayIntegration()
    ],
    tracesSampleRate: 1.0,
    debug: true,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0
})

function App() {
    return (
        // @ts-ignore
        <Auth0Provider
            domain={process.env.REACT_APP_AUTH0_DOMAIN!}
            clientId={process.env.REACT_APP_AUTH0_CLIENT_ID!}
            authorizationParams={{
                redirect_uri: window.location.origin,
                audience: process.env.REACT_APP_AUTH0_AUDIENCE,
                scope: "prepare:announcements review:announcements email profile"
            }}
        >
            <Provider store={store}>
                <AppInternal/>
            </Provider>
        </Auth0Provider>
    )
}

const container = new Container();

function AppInternal() {

    const auth = useAuth0();
    const dispatch = useDispatch();

    useEffect(() => {
        if (!auth.isLoading) {
            auth.getAccessTokenSilently().then(t => {
                container.bind(ApiService).toDynamicValue(() => new ApiService(t)).inSingletonScope();
                auth.getIdTokenClaims().then(c => {

                    dispatch(setCurrentUser({email: c!.email!, roles: (jwt_decode(t) as any).permissions || []}));
                });
            });
        }
    }, [auth.isLoading]);

    return <InversifyProvider container={container}>
        <BrowserRouter>
            <Layout/>
        </BrowserRouter>
    </InversifyProvider>
}

const CrudDefaultTheme = {
    components: {
        Button: {
            colorBgContainerDisabled: "#E5EDFB",
            controlHeight: 34,
        },
        Input: {
            controlHeight: 34
        },
        Layout: {
            colorBgHeader: "#FFFFFF",
            colorBgTrigger: "#FFFFFF",
            colorBgBody: "#FFFFFF",
        }
    },
    token: {
        borderRadius: 24,
        colorPrimary: "#3662DB",
        colorWarning: "#DE6826",

    },
}

function Layout() {
    const auth = useAuth0();
    const roles = useCurrentUserRoles();
    const [showSider, setShowSider] = useState<boolean>(window.innerWidth > 425);

    useEffect(() => {
        const resize = () => {
            const shouldShowSider = window.innerWidth > 425;
            if (shouldShowSider !== showSider) setShowSider(shouldShowSider);
        }
        window.addEventListener('resize', resize);
        return () => window.removeEventListener('resize', resize);
    }, [showSider]);

    return <ConfigProvider theme={CrudDefaultTheme}>
        <CrudLayout
            siderFullHeight={false}
            breadcrumb={<CrudBreadcrumb routingDefinitions={RoutingPaths}/>}
            siderMenu={<SiderMenu/>}
            header={<HeaderContainer/>}
            footerContent={<FooterContainer/>}
            showSider={auth.isAuthenticated && isAuthorizedForCms(roles) && showSider}
        >
            <RoutingContainer/>
        </CrudLayout>
    </ConfigProvider>;
}


export const mainColor = "#C41D7F";

export default App;
