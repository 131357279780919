import React from "react";
import {Route, Routes} from "react-router-dom";
import {RoutingDefinition} from "digimuth-components/dist/components/crudBreadcrumb/crudBreadcrumb";
import styles from "./routingContainer.module.less";
import {LoadingOutlined} from "@ant-design/icons";
import {Spin} from "antd";
import * as Pages from "./pages";

const mainPage = {route: "/", name: "Strona główna"};

export const RoutingPaths: { [key: string]: RoutingDefinition } = {
    categoryTable: { route: "/category" },
    announcementTable: {route: "/announcement"},
    userTable: {route: "/user"},

    login: {route: "/login"},
    activate: {route: "/activate"},
    forgotPassword: {route: "/forgotPassword"},
    forgotPasswordComplete: {route: "/forgotPasswordComplete"},
    mainPage
};

const loadingIcon = <LoadingOutlined className={styles.loadingIcon} spin/>;
export const loadingSpin = <Spin className={styles.loadingContainer} indicator={loadingIcon}/>;

export default function RoutingContainer() {
    return <Routes>
        <Route path={RoutingPaths.categoryTable.route} element={<Pages.CategoryPage />} />
        <Route path={RoutingPaths.announcementTable.route} element={<Pages.AnnouncementPage/>}/>
        <Route path={RoutingPaths.userTable.route} element={<Pages.UserPage/>}/>
        <Route path={"/"} element={<Pages.MainPage/>}/>
    </Routes>
}
