import { createSlice } from "@reduxjs/toolkit";

const initialState: { currentUser: { roles: string[], email: string } | null } = {
    currentUser: null
};

const applicationUserSlice = createSlice({
    name: 'applicationUserSlice',
    initialState,
    reducers: {
        setCurrentUser: (state, action: { payload: { roles: string[], email: string } | null }) => {
            state.currentUser = action.payload;
        }
    }
});

// these methods only modify the state
export const { setCurrentUser } = applicationUserSlice.actions;

// these methods only read the state
export const getCurrentUser = (state: any) => state.applicationUser.currentUser as { roles: string[], email: string } | null;

export default applicationUserSlice.reducer;
