/* tslint:disable */
/* eslint-disable */
/**
 * Digimuth.KatowickaAgencjaWydawnicza.Web
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @enum {string}
 */
export enum AnnouncementCategory {
    Culture = 'Culture',
    Sport = 'Sport',
    Entertainment = 'Entertainment',
    Ecology = 'Ecology',
    Animals = 'Animals',
    Motorization = 'Motorization',
    Road = 'Road'
}

/**
 * 
 * @export
 * @enum {string}
 */
export enum AnnouncementCategoryType {
    General = 'General',
    Normal = 'Normal',
    DefaultSelected = 'DefaultSelected'
}

/**
 * 
 * @export
 * @interface AnnouncementCmsDto
 */
export interface AnnouncementCmsDto {
    /**
     * 
     * @type {number}
     * @memberof AnnouncementCmsDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof AnnouncementCmsDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof AnnouncementCmsDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof AnnouncementCmsDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof AnnouncementCmsDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {string}
     * @memberof AnnouncementCmsDto
     */
    createdByEmail?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AnnouncementCmsDto
     */
    updatedByEmail?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AnnouncementCmsDto
     */
    text?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AnnouncementCmsDto
     */
    title?: string | null;
    /**
     * 
     * @type {AnnouncementCategory}
     * @memberof AnnouncementCmsDto
     */
    category?: AnnouncementCategory;
    /**
     * 
     * @type {Array<number>}
     * @memberof AnnouncementCmsDto
     */
    selectedCategoriesIds?: Array<number> | null;
    /**
     * 
     * @type {Array<CategoryDto>}
     * @memberof AnnouncementCmsDto
     */
    selectedCategories?: Array<CategoryDto> | null;
    /**
     * 
     * @type {string}
     * @memberof AnnouncementCmsDto
     */
    publicationDate?: string | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof AnnouncementCmsDto
     */
    districtsIds?: Array<number> | null;
    /**
     * 
     * @type {Array<DistrictMinimalDto>}
     * @memberof AnnouncementCmsDto
     */
    districts?: Array<DistrictMinimalDto> | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof AnnouncementCmsDto
     */
    streetsIds?: Array<number> | null;
    /**
     * 
     * @type {Array<StreetMinimalDto>}
     * @memberof AnnouncementCmsDto
     */
    streets?: Array<StreetMinimalDto> | null;
    /**
     * 
     * @type {number}
     * @memberof AnnouncementCmsDto
     */
    imageId?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof AnnouncementCmsDto
     */
    forKatowice?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof AnnouncementCmsDto
     */
    forChildren?: boolean | null;
    /**
     * 
     * @type {AnnouncementStatus}
     * @memberof AnnouncementCmsDto
     */
    status?: AnnouncementStatus;
    /**
     * 
     * @type {Array<FormOfTransport>}
     * @memberof AnnouncementCmsDto
     */
    formsOfTransport?: Array<FormOfTransport> | null;
    /**
     * 
     * @type {Array<EconomicActivity>}
     * @memberof AnnouncementCmsDto
     */
    economicActivity?: Array<EconomicActivity> | null;
    /**
     * 
     * @type {Array<TypeOfAccommodation>}
     * @memberof AnnouncementCmsDto
     */
    typeOfAccommodation?: Array<TypeOfAccommodation> | null;
}
/**
 * 
 * @export
 * @interface AnnouncementCreateDto
 */
export interface AnnouncementCreateDto {
    /**
     * 
     * @type {number}
     * @memberof AnnouncementCreateDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof AnnouncementCreateDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof AnnouncementCreateDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof AnnouncementCreateDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof AnnouncementCreateDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {string}
     * @memberof AnnouncementCreateDto
     */
    createdByEmail?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AnnouncementCreateDto
     */
    updatedByEmail?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AnnouncementCreateDto
     */
    text?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AnnouncementCreateDto
     */
    title?: string | null;
    /**
     * 
     * @type {AnnouncementCategory}
     * @memberof AnnouncementCreateDto
     */
    category?: AnnouncementCategory;
    /**
     * 
     * @type {Array<number>}
     * @memberof AnnouncementCreateDto
     */
    selectedCategoriesIds?: Array<number> | null;
    /**
     * 
     * @type {Array<CategoryDto>}
     * @memberof AnnouncementCreateDto
     */
    selectedCategories?: Array<CategoryDto> | null;
    /**
     * 
     * @type {string}
     * @memberof AnnouncementCreateDto
     */
    publicationDate?: string | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof AnnouncementCreateDto
     */
    districtsIds?: Array<number> | null;
    /**
     * 
     * @type {Array<DistrictMinimalDto>}
     * @memberof AnnouncementCreateDto
     */
    districts?: Array<DistrictMinimalDto> | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof AnnouncementCreateDto
     */
    streetsIds?: Array<number> | null;
    /**
     * 
     * @type {Array<StreetMinimalDto>}
     * @memberof AnnouncementCreateDto
     */
    streets?: Array<StreetMinimalDto> | null;
    /**
     * 
     * @type {boolean}
     * @memberof AnnouncementCreateDto
     */
    forKatowice?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof AnnouncementCreateDto
     */
    forChildren?: boolean | null;
    /**
     * 
     * @type {AnnouncementStatus}
     * @memberof AnnouncementCreateDto
     */
    status?: AnnouncementStatus;
    /**
     * 
     * @type {Array<FormOfTransport>}
     * @memberof AnnouncementCreateDto
     */
    formsOfTransport?: Array<FormOfTransport> | null;
    /**
     * 
     * @type {Array<EconomicActivity>}
     * @memberof AnnouncementCreateDto
     */
    economicActivity?: Array<EconomicActivity> | null;
    /**
     * 
     * @type {Array<TypeOfAccommodation>}
     * @memberof AnnouncementCreateDto
     */
    typeOfAccommodation?: Array<TypeOfAccommodation> | null;
    /**
     * 
     * @type {number}
     * @memberof AnnouncementCreateDto
     */
    imageId?: number | null;
}
/**
 * 
 * @export
 * @interface AnnouncementDetailsDto
 */
export interface AnnouncementDetailsDto {
    /**
     * 
     * @type {number}
     * @memberof AnnouncementDetailsDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof AnnouncementDetailsDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof AnnouncementDetailsDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof AnnouncementDetailsDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof AnnouncementDetailsDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {string}
     * @memberof AnnouncementDetailsDto
     */
    createdByEmail?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AnnouncementDetailsDto
     */
    updatedByEmail?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AnnouncementDetailsDto
     */
    text?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AnnouncementDetailsDto
     */
    title?: string | null;
    /**
     * 
     * @type {AnnouncementCategory}
     * @memberof AnnouncementDetailsDto
     */
    category?: AnnouncementCategory;
    /**
     * 
     * @type {Array<number>}
     * @memberof AnnouncementDetailsDto
     */
    selectedCategoriesIds?: Array<number> | null;
    /**
     * 
     * @type {Array<CategoryDto>}
     * @memberof AnnouncementDetailsDto
     */
    selectedCategories?: Array<CategoryDto> | null;
    /**
     * 
     * @type {string}
     * @memberof AnnouncementDetailsDto
     */
    publicationDate?: string | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof AnnouncementDetailsDto
     */
    districtsIds?: Array<number> | null;
    /**
     * 
     * @type {Array<DistrictMinimalDto>}
     * @memberof AnnouncementDetailsDto
     */
    districts?: Array<DistrictMinimalDto> | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof AnnouncementDetailsDto
     */
    streetsIds?: Array<number> | null;
    /**
     * 
     * @type {Array<StreetMinimalDto>}
     * @memberof AnnouncementDetailsDto
     */
    streets?: Array<StreetMinimalDto> | null;
    /**
     * 
     * @type {number}
     * @memberof AnnouncementDetailsDto
     */
    imageId?: number | null;
    /**
     * 
     * @type {AnnouncementStatus}
     * @memberof AnnouncementDetailsDto
     */
    status?: AnnouncementStatus;
}
/**
 * 
 * @export
 * @interface AnnouncementDto
 */
export interface AnnouncementDto {
    /**
     * 
     * @type {number}
     * @memberof AnnouncementDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof AnnouncementDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof AnnouncementDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof AnnouncementDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof AnnouncementDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {string}
     * @memberof AnnouncementDto
     */
    createdByEmail?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AnnouncementDto
     */
    updatedByEmail?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AnnouncementDto
     */
    text?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AnnouncementDto
     */
    title?: string | null;
    /**
     * 
     * @type {AnnouncementCategory}
     * @memberof AnnouncementDto
     */
    category?: AnnouncementCategory;
    /**
     * 
     * @type {Array<number>}
     * @memberof AnnouncementDto
     */
    selectedCategoriesIds?: Array<number> | null;
    /**
     * 
     * @type {Array<CategoryDto>}
     * @memberof AnnouncementDto
     */
    selectedCategories?: Array<CategoryDto> | null;
    /**
     * 
     * @type {string}
     * @memberof AnnouncementDto
     */
    publicationDate?: string | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof AnnouncementDto
     */
    districtsIds?: Array<number> | null;
    /**
     * 
     * @type {Array<DistrictMinimalDto>}
     * @memberof AnnouncementDto
     */
    districts?: Array<DistrictMinimalDto> | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof AnnouncementDto
     */
    streetsIds?: Array<number> | null;
    /**
     * 
     * @type {Array<StreetMinimalDto>}
     * @memberof AnnouncementDto
     */
    streets?: Array<StreetMinimalDto> | null;
    /**
     * 
     * @type {number}
     * @memberof AnnouncementDto
     */
    imageId?: number | null;
}
/**
 * 
 * @export
 * @interface AnnouncementImageDetailsDto
 */
export interface AnnouncementImageDetailsDto {
    /**
     * 
     * @type {number}
     * @memberof AnnouncementImageDetailsDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof AnnouncementImageDetailsDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof AnnouncementImageDetailsDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof AnnouncementImageDetailsDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof AnnouncementImageDetailsDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {string}
     * @memberof AnnouncementImageDetailsDto
     */
    fileName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AnnouncementImageDetailsDto
     */
    contentType?: string | null;
}
/**
 * 
 * @export
 * @interface AnnouncementImageDto
 */
export interface AnnouncementImageDto {
    /**
     * 
     * @type {number}
     * @memberof AnnouncementImageDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof AnnouncementImageDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof AnnouncementImageDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof AnnouncementImageDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof AnnouncementImageDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {number}
     * @memberof AnnouncementImageDto
     */
    announcementId?: number;
}
/**
 * 
 * @export
 * @interface AnnouncementImageUpdateDto
 */
export interface AnnouncementImageUpdateDto {
    /**
     * 
     * @type {number}
     * @memberof AnnouncementImageUpdateDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof AnnouncementImageUpdateDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof AnnouncementImageUpdateDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof AnnouncementImageUpdateDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof AnnouncementImageUpdateDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {number}
     * @memberof AnnouncementImageUpdateDto
     */
    announcementId?: number;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum AnnouncementStatus {
    Draft = 'Draft',
    ToBeApproved = 'ToBeApproved',
    Rejected = 'Rejected',
    WaitingToBeSent = 'WaitingToBeSent',
    Sent = 'Sent'
}

/**
 * 
 * @export
 * @interface AnnouncementStatusUpdateDto
 */
export interface AnnouncementStatusUpdateDto {
    /**
     * 
     * @type {AnnouncementStatus}
     * @memberof AnnouncementStatusUpdateDto
     */
    announcementStatus?: AnnouncementStatus;
    /**
     * 
     * @type {string}
     * @memberof AnnouncementStatusUpdateDto
     */
    publishDateTime?: string | null;
}
/**
 * 
 * @export
 * @interface AnnouncementUpdateDto
 */
export interface AnnouncementUpdateDto {
    /**
     * 
     * @type {number}
     * @memberof AnnouncementUpdateDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof AnnouncementUpdateDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof AnnouncementUpdateDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof AnnouncementUpdateDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof AnnouncementUpdateDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {string}
     * @memberof AnnouncementUpdateDto
     */
    createdByEmail?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AnnouncementUpdateDto
     */
    updatedByEmail?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AnnouncementUpdateDto
     */
    text?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AnnouncementUpdateDto
     */
    title?: string | null;
    /**
     * 
     * @type {AnnouncementCategory}
     * @memberof AnnouncementUpdateDto
     */
    category?: AnnouncementCategory;
    /**
     * 
     * @type {Array<number>}
     * @memberof AnnouncementUpdateDto
     */
    selectedCategoriesIds?: Array<number> | null;
    /**
     * 
     * @type {Array<CategoryDto>}
     * @memberof AnnouncementUpdateDto
     */
    selectedCategories?: Array<CategoryDto> | null;
    /**
     * 
     * @type {string}
     * @memberof AnnouncementUpdateDto
     */
    publicationDate?: string | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof AnnouncementUpdateDto
     */
    districtsIds?: Array<number> | null;
    /**
     * 
     * @type {Array<DistrictMinimalDto>}
     * @memberof AnnouncementUpdateDto
     */
    districts?: Array<DistrictMinimalDto> | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof AnnouncementUpdateDto
     */
    streetsIds?: Array<number> | null;
    /**
     * 
     * @type {Array<StreetMinimalDto>}
     * @memberof AnnouncementUpdateDto
     */
    streets?: Array<StreetMinimalDto> | null;
    /**
     * 
     * @type {number}
     * @memberof AnnouncementUpdateDto
     */
    imageId?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof AnnouncementUpdateDto
     */
    forKatowice?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof AnnouncementUpdateDto
     */
    forChildren?: boolean | null;
    /**
     * 
     * @type {Array<FormOfTransport>}
     * @memberof AnnouncementUpdateDto
     */
    formsOfTransport?: Array<FormOfTransport> | null;
    /**
     * 
     * @type {Array<EconomicActivity>}
     * @memberof AnnouncementUpdateDto
     */
    economicActivity?: Array<EconomicActivity> | null;
    /**
     * 
     * @type {Array<TypeOfAccommodation>}
     * @memberof AnnouncementUpdateDto
     */
    typeOfAccommodation?: Array<TypeOfAccommodation> | null;
}
/**
 * 
 * @export
 * @interface AnnouncementsRecipientsCountDto
 */
export interface AnnouncementsRecipientsCountDto {
    /**
     * 
     * @type {number}
     * @memberof AnnouncementsRecipientsCountDto
     */
    count?: number;
}
/**
 * 
 * @export
 * @interface ApplicationUserDetailsCmsDto
 */
export interface ApplicationUserDetailsCmsDto {
    /**
     * 
     * @type {number}
     * @memberof ApplicationUserDetailsCmsDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof ApplicationUserDetailsCmsDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof ApplicationUserDetailsCmsDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof ApplicationUserDetailsCmsDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ApplicationUserDetailsCmsDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationUserDetailsCmsDto
     */
    userName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationUserDetailsCmsDto
     */
    email?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ApplicationUserDetailsCmsDto
     */
    emailConfirmed?: boolean;
    /**
     * 
     * @type {Array<DistrictDto>}
     * @memberof ApplicationUserDetailsCmsDto
     */
    districts?: Array<DistrictDto> | null;
    /**
     * 
     * @type {Array<AnnouncementCategory>}
     * @memberof ApplicationUserDetailsCmsDto
     */
    categories?: Array<AnnouncementCategory> | null;
    /**
     * 
     * @type {Array<FormOfTransport>}
     * @memberof ApplicationUserDetailsCmsDto
     */
    formsOfTransport?: Array<FormOfTransport> | null;
    /**
     * 
     * @type {Array<EconomicActivity>}
     * @memberof ApplicationUserDetailsCmsDto
     */
    economicActivity?: Array<EconomicActivity> | null;
    /**
     * 
     * @type {Array<TypeOfAccommodation>}
     * @memberof ApplicationUserDetailsCmsDto
     */
    typeOfAccommodation?: Array<TypeOfAccommodation> | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof ApplicationUserDetailsCmsDto
     */
    selectedCategoriesIds?: Array<number> | null;
    /**
     * 
     * @type {Array<CategoryDto>}
     * @memberof ApplicationUserDetailsCmsDto
     */
    selectedCategories?: Array<CategoryDto> | null;
    /**
     * 
     * @type {boolean}
     * @memberof ApplicationUserDetailsCmsDto
     */
    livesIsKatowice?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof ApplicationUserDetailsCmsDto
     */
    hasChildren?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationUserDetailsCmsDto
     */
    auth0Id?: string | null;
}
/**
 * 
 * @export
 * @interface ApplicationUserDetailsDto
 */
export interface ApplicationUserDetailsDto {
    /**
     * 
     * @type {number}
     * @memberof ApplicationUserDetailsDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof ApplicationUserDetailsDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof ApplicationUserDetailsDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof ApplicationUserDetailsDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ApplicationUserDetailsDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationUserDetailsDto
     */
    userName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationUserDetailsDto
     */
    email?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ApplicationUserDetailsDto
     */
    emailConfirmed?: boolean;
    /**
     * 
     * @type {UserAppProgress}
     * @memberof ApplicationUserDetailsDto
     */
    userAppProgress?: UserAppProgress;
    /**
     * 
     * @type {Array<DistrictDto>}
     * @memberof ApplicationUserDetailsDto
     */
    districts?: Array<DistrictDto> | null;
    /**
     * 
     * @type {AnnouncementCategory}
     * @memberof ApplicationUserDetailsDto
     */
    categories?: AnnouncementCategory;
    /**
     * 
     * @type {Array<number>}
     * @memberof ApplicationUserDetailsDto
     */
    selectedCategoriesIds?: Array<number> | null;
    /**
     * 
     * @type {Array<CategoryDto>}
     * @memberof ApplicationUserDetailsDto
     */
    selectedCategories?: Array<CategoryDto> | null;
    /**
     * 
     * @type {FormOfTransport}
     * @memberof ApplicationUserDetailsDto
     */
    formsOfTransport?: FormOfTransport;
    /**
     * 
     * @type {EconomicActivity}
     * @memberof ApplicationUserDetailsDto
     */
    economicActivity?: EconomicActivity;
    /**
     * 
     * @type {TypeOfAccommodation}
     * @memberof ApplicationUserDetailsDto
     */
    typeOfAccommodation?: TypeOfAccommodation;
    /**
     * 
     * @type {boolean}
     * @memberof ApplicationUserDetailsDto
     */
    livesIsKatowice?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof ApplicationUserDetailsDto
     */
    hasChildren?: boolean | null;
}
/**
 * 
 * @export
 * @interface ApplicationUserDto
 */
export interface ApplicationUserDto {
    /**
     * 
     * @type {number}
     * @memberof ApplicationUserDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof ApplicationUserDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof ApplicationUserDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof ApplicationUserDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ApplicationUserDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationUserDto
     */
    userName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationUserDto
     */
    email?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ApplicationUserDto
     */
    emailConfirmed?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ApplicationUserDto
     */
    auth0Id?: string | null;
}
/**
 * 
 * @export
 * @interface CategoryCreateDto
 */
export interface CategoryCreateDto {
    /**
     * 
     * @type {number}
     * @memberof CategoryCreateDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof CategoryCreateDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof CategoryCreateDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof CategoryCreateDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof CategoryCreateDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {string}
     * @memberof CategoryCreateDto
     */
    name?: string | null;
    /**
     * 
     * @type {number}
     * @memberof CategoryCreateDto
     */
    lightThemeImageId?: number | null;
    /**
     * 
     * @type {ImageDto}
     * @memberof CategoryCreateDto
     */
    lightThemeImage?: ImageDto;
    /**
     * 
     * @type {number}
     * @memberof CategoryCreateDto
     */
    darkThemeImageId?: number | null;
    /**
     * 
     * @type {ImageDto}
     * @memberof CategoryCreateDto
     */
    darkThemeImage?: ImageDto;
    /**
     * 
     * @type {boolean}
     * @memberof CategoryCreateDto
     */
    isDeleted?: boolean;
    /**
     * 
     * @type {AnnouncementCategoryType}
     * @memberof CategoryCreateDto
     */
    type?: AnnouncementCategoryType;
    /**
     * 
     * @type {Array<number>}
     * @memberof CategoryCreateDto
     */
    applicationUsersIds?: Array<number> | null;
}
/**
 * 
 * @export
 * @interface CategoryDetailsDto
 */
export interface CategoryDetailsDto {
    /**
     * 
     * @type {number}
     * @memberof CategoryDetailsDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof CategoryDetailsDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof CategoryDetailsDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof CategoryDetailsDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof CategoryDetailsDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {string}
     * @memberof CategoryDetailsDto
     */
    name?: string | null;
    /**
     * 
     * @type {number}
     * @memberof CategoryDetailsDto
     */
    lightThemeImageId?: number | null;
    /**
     * 
     * @type {ImageDto}
     * @memberof CategoryDetailsDto
     */
    lightThemeImage?: ImageDto;
    /**
     * 
     * @type {number}
     * @memberof CategoryDetailsDto
     */
    darkThemeImageId?: number | null;
    /**
     * 
     * @type {ImageDto}
     * @memberof CategoryDetailsDto
     */
    darkThemeImage?: ImageDto;
    /**
     * 
     * @type {boolean}
     * @memberof CategoryDetailsDto
     */
    isDeleted?: boolean;
    /**
     * 
     * @type {AnnouncementCategoryType}
     * @memberof CategoryDetailsDto
     */
    type?: AnnouncementCategoryType;
}
/**
 * 
 * @export
 * @interface CategoryDto
 */
export interface CategoryDto {
    /**
     * 
     * @type {number}
     * @memberof CategoryDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof CategoryDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof CategoryDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof CategoryDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof CategoryDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {string}
     * @memberof CategoryDto
     */
    name?: string | null;
    /**
     * 
     * @type {number}
     * @memberof CategoryDto
     */
    lightThemeImageId?: number | null;
    /**
     * 
     * @type {ImageDto}
     * @memberof CategoryDto
     */
    lightThemeImage?: ImageDto;
    /**
     * 
     * @type {number}
     * @memberof CategoryDto
     */
    darkThemeImageId?: number | null;
    /**
     * 
     * @type {ImageDto}
     * @memberof CategoryDto
     */
    darkThemeImage?: ImageDto;
    /**
     * 
     * @type {boolean}
     * @memberof CategoryDto
     */
    isDeleted?: boolean;
    /**
     * 
     * @type {AnnouncementCategoryType}
     * @memberof CategoryDto
     */
    type?: AnnouncementCategoryType;
}
/**
 * 
 * @export
 * @interface CategoryUpdateDto
 */
export interface CategoryUpdateDto {
    /**
     * 
     * @type {number}
     * @memberof CategoryUpdateDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof CategoryUpdateDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof CategoryUpdateDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof CategoryUpdateDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof CategoryUpdateDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {string}
     * @memberof CategoryUpdateDto
     */
    name?: string | null;
    /**
     * 
     * @type {number}
     * @memberof CategoryUpdateDto
     */
    lightThemeImageId?: number | null;
    /**
     * 
     * @type {ImageDto}
     * @memberof CategoryUpdateDto
     */
    lightThemeImage?: ImageDto;
    /**
     * 
     * @type {number}
     * @memberof CategoryUpdateDto
     */
    darkThemeImageId?: number | null;
    /**
     * 
     * @type {ImageDto}
     * @memberof CategoryUpdateDto
     */
    darkThemeImage?: ImageDto;
    /**
     * 
     * @type {boolean}
     * @memberof CategoryUpdateDto
     */
    isDeleted?: boolean;
    /**
     * 
     * @type {AnnouncementCategoryType}
     * @memberof CategoryUpdateDto
     */
    type?: AnnouncementCategoryType;
}
/**
 * 
 * @export
 * @interface DistrictCreateDto
 */
export interface DistrictCreateDto {
    /**
     * 
     * @type {number}
     * @memberof DistrictCreateDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof DistrictCreateDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof DistrictCreateDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof DistrictCreateDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof DistrictCreateDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {string}
     * @memberof DistrictCreateDto
     */
    name?: string | null;
}
/**
 * 
 * @export
 * @interface DistrictDetailsDto
 */
export interface DistrictDetailsDto {
    /**
     * 
     * @type {number}
     * @memberof DistrictDetailsDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof DistrictDetailsDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof DistrictDetailsDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof DistrictDetailsDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof DistrictDetailsDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {string}
     * @memberof DistrictDetailsDto
     */
    name?: string | null;
    /**
     * 
     * @type {Array<StreetDto>}
     * @memberof DistrictDetailsDto
     */
    roads?: Array<StreetDto> | null;
    /**
     * 
     * @type {Array<AnnouncementDto>}
     * @memberof DistrictDetailsDto
     */
    announcement?: Array<AnnouncementDto> | null;
}
/**
 * 
 * @export
 * @interface DistrictDto
 */
export interface DistrictDto {
    /**
     * 
     * @type {number}
     * @memberof DistrictDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof DistrictDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof DistrictDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof DistrictDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof DistrictDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {string}
     * @memberof DistrictDto
     */
    name?: string | null;
}
/**
 * 
 * @export
 * @interface DistrictMinimalDto
 */
export interface DistrictMinimalDto {
    /**
     * 
     * @type {number}
     * @memberof DistrictMinimalDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof DistrictMinimalDto
     */
    name?: string | null;
}
/**
 * 
 * @export
 * @interface DistrictUpdateDto
 */
export interface DistrictUpdateDto {
    /**
     * 
     * @type {number}
     * @memberof DistrictUpdateDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof DistrictUpdateDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof DistrictUpdateDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof DistrictUpdateDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof DistrictUpdateDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {string}
     * @memberof DistrictUpdateDto
     */
    name?: string | null;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum EconomicActivity {
    NoActivity = 'NoActivity',
    EconomicActivity = 'EconomicActivity',
    Ngo = 'Ngo'
}

/**
 * 
 * @export
 * @enum {string}
 */
export enum FormOfTransport {
    Car = 'Car',
    PublicTransport = 'PublicTransport',
    Bike = 'Bike'
}

/**
 * 
 * @export
 * @interface ImageDetailsDto
 */
export interface ImageDetailsDto {
    /**
     * 
     * @type {number}
     * @memberof ImageDetailsDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof ImageDetailsDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof ImageDetailsDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof ImageDetailsDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ImageDetailsDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ImageDetailsDto
     */
    fileName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ImageDetailsDto
     */
    contentType?: string | null;
}
/**
 * 
 * @export
 * @interface ImageDto
 */
export interface ImageDto {
    /**
     * 
     * @type {number}
     * @memberof ImageDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof ImageDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof ImageDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof ImageDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ImageDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ImageDto
     */
    fileName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ImageDto
     */
    contentType?: string | null;
}
/**
 * 
 * @export
 * @interface ImageUpdateDto
 */
export interface ImageUpdateDto {
    /**
     * 
     * @type {number}
     * @memberof ImageUpdateDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof ImageUpdateDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof ImageUpdateDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof ImageUpdateDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ImageUpdateDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ImageUpdateDto
     */
    fileName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ImageUpdateDto
     */
    contentType?: string | null;
}
/**
 * 
 * @export
 * @interface LatestAnnouncementsAndNewsDto
 */
export interface LatestAnnouncementsAndNewsDto {
    /**
     * 
     * @type {Array<AnnouncementDto>}
     * @memberof LatestAnnouncementsAndNewsDto
     */
    announcements?: Array<AnnouncementDto> | null;
    /**
     * 
     * @type {Array<NewsRssDto>}
     * @memberof LatestAnnouncementsAndNewsDto
     */
    newsItems?: Array<NewsRssDto> | null;
}
/**
 * 
 * @export
 * @interface NewsImageDto
 */
export interface NewsImageDto {
    /**
     * 
     * @type {string}
     * @memberof NewsImageDto
     */
    url?: string | null;
}
/**
 * 
 * @export
 * @interface NewsRssDto
 */
export interface NewsRssDto {
    /**
     * 
     * @type {string}
     * @memberof NewsRssDto
     */
    title?: string | null;
    /**
     * 
     * @type {string}
     * @memberof NewsRssDto
     */
    link?: string | null;
    /**
     * 
     * @type {string}
     * @memberof NewsRssDto
     */
    guid?: string | null;
    /**
     * 
     * @type {string}
     * @memberof NewsRssDto
     */
    description?: string | null;
    /**
     * 
     * @type {string}
     * @memberof NewsRssDto
     */
    category?: string | null;
    /**
     * 
     * @type {NewsImageDto}
     * @memberof NewsRssDto
     */
    newsImageDto?: NewsImageDto;
    /**
     * 
     * @type {string}
     * @memberof NewsRssDto
     */
    pubDate?: string;
}
/**
 * 
 * @export
 * @interface ScheduleEntry
 */
export interface ScheduleEntry {
    /**
     * 
     * @type {string}
     * @memberof ScheduleEntry
     */
    date?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ScheduleEntry
     */
    past?: boolean;
}
/**
 * 
 * @export
 * @interface StreetCreateDto
 */
export interface StreetCreateDto {
    /**
     * 
     * @type {number}
     * @memberof StreetCreateDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof StreetCreateDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof StreetCreateDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof StreetCreateDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof StreetCreateDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {string}
     * @memberof StreetCreateDto
     */
    name?: string | null;
    /**
     * 
     * @type {number}
     * @memberof StreetCreateDto
     */
    districtId?: number;
}
/**
 * 
 * @export
 * @interface StreetDetailsDto
 */
export interface StreetDetailsDto {
    /**
     * 
     * @type {number}
     * @memberof StreetDetailsDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof StreetDetailsDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof StreetDetailsDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof StreetDetailsDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof StreetDetailsDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {string}
     * @memberof StreetDetailsDto
     */
    name?: string | null;
    /**
     * 
     * @type {number}
     * @memberof StreetDetailsDto
     */
    districtId?: number;
    /**
     * 
     * @type {DistrictDto}
     * @memberof StreetDetailsDto
     */
    district?: DistrictDto;
    /**
     * 
     * @type {Array<AnnouncementDto>}
     * @memberof StreetDetailsDto
     */
    announcements?: Array<AnnouncementDto> | null;
}
/**
 * 
 * @export
 * @interface StreetDto
 */
export interface StreetDto {
    /**
     * 
     * @type {number}
     * @memberof StreetDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof StreetDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof StreetDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof StreetDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof StreetDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {string}
     * @memberof StreetDto
     */
    name?: string | null;
    /**
     * 
     * @type {number}
     * @memberof StreetDto
     */
    districtId?: number;
}
/**
 * 
 * @export
 * @interface StreetMinimalDto
 */
export interface StreetMinimalDto {
    /**
     * 
     * @type {number}
     * @memberof StreetMinimalDto
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof StreetMinimalDto
     */
    districtId?: number;
    /**
     * 
     * @type {string}
     * @memberof StreetMinimalDto
     */
    name?: string | null;
}
/**
 * 
 * @export
 * @interface StreetUpdateDto
 */
export interface StreetUpdateDto {
    /**
     * 
     * @type {number}
     * @memberof StreetUpdateDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof StreetUpdateDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof StreetUpdateDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof StreetUpdateDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof StreetUpdateDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {string}
     * @memberof StreetUpdateDto
     */
    name?: string | null;
    /**
     * 
     * @type {number}
     * @memberof StreetUpdateDto
     */
    districtId?: number;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum TypeOfAccommodation {
    CouncilFlat = 'CouncilFlat',
    Ktbs = 'Ktbs',
    CommunityFlat = 'CommunityFlat',
    Other = 'Other'
}

/**
 * 
 * @export
 * @enum {string}
 */
export enum UserAppProgress {
    OpenedApp = 'OpenedApp',
    LivePlaceConfirmed = 'LivePlaceConfirmed',
    DistrictsChosen = 'DistrictsChosen',
    ChildrenConfirmed = 'ChildrenConfirmed',
    CategoriesChosen = 'CategoriesChosen',
    TransportTypesChosen = 'TransportTypesChosen',
    EconomicActivityChosen = 'EconomicActivityChosen',
    AccommodationTypeChosen = 'AccommodationTypeChosen'
}

/**
 * 
 * @export
 * @interface WasteHomeNumberDto
 */
export interface WasteHomeNumberDto {
    /**
     * 
     * @type {string}
     * @memberof WasteHomeNumberDto
     */
    value?: string | null;
}
/**
 * 
 * @export
 * @interface WasteScheduleDto
 */
export interface WasteScheduleDto {
    /**
     * 
     * @type {string}
     * @memberof WasteScheduleDto
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WasteScheduleDto
     */
    type?: string | null;
    /**
     * 
     * @type {Array<ScheduleEntry>}
     * @memberof WasteScheduleDto
     */
    dates?: Array<ScheduleEntry> | null;
}
/**
 * 
 * @export
 * @interface WasteStreetDto
 */
export interface WasteStreetDto {
    /**
     * 
     * @type {string}
     * @memberof WasteStreetDto
     */
    value?: string | null;
}

/**
 * AnnouncementApi - axios parameter creator
 * @export
 */
export const AnnouncementApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAnnouncementAnnouncementForEditGet: async (id?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Announcement/announcementForEdit`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAnnouncementAnnouncementsForCmsGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Announcement/announcementsForCms`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAnnouncementGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Announcement`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAnnouncementIdDelete: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiAnnouncementIdDelete', 'id', id)
            const localVarPath = `/api/Announcement/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAnnouncementIdGet: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiAnnouncementIdGet', 'id', id)
            const localVarPath = `/api/Announcement/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {AnnouncementUpdateDto} [announcementUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAnnouncementIdPut: async (id: number, announcementUpdateDto?: AnnouncementUpdateDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiAnnouncementIdPut', 'id', id)
            const localVarPath = `/api/Announcement/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(announcementUpdateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {AnnouncementStatusUpdateDto} [announcementStatusUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAnnouncementIdStatusPut: async (id: number, announcementStatusUpdateDto?: AnnouncementStatusUpdateDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiAnnouncementIdStatusPut', 'id', id)
            const localVarPath = `/api/Announcement/{id}/status`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(announcementStatusUpdateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAnnouncementLatestAnnouncementsAndNewsGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Announcement/latestAnnouncementsAndNews`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} page 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAnnouncementPagePageGet: async (page: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'page' is not null or undefined
            assertParamExists('apiAnnouncementPagePageGet', 'page', page)
            const localVarPath = `/api/Announcement/page/{page}`
                .replace(`{${"page"}}`, encodeURIComponent(String(page)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AnnouncementCreateDto} [announcementCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAnnouncementPost: async (announcementCreateDto?: AnnouncementCreateDto, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Announcement`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(announcementCreateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAnnouncementRecipientsNumberGet: async (id?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Announcement/recipientsNumber`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AnnouncementApi - functional programming interface
 * @export
 */
export const AnnouncementApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AnnouncementApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAnnouncementAnnouncementForEditGet(id?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AnnouncementUpdateDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAnnouncementAnnouncementForEditGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAnnouncementAnnouncementsForCmsGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AnnouncementCmsDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAnnouncementAnnouncementsForCmsGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAnnouncementGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AnnouncementDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAnnouncementGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAnnouncementIdDelete(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAnnouncementIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAnnouncementIdGet(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AnnouncementDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAnnouncementIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {AnnouncementUpdateDto} [announcementUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAnnouncementIdPut(id: number, announcementUpdateDto?: AnnouncementUpdateDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AnnouncementDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAnnouncementIdPut(id, announcementUpdateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {AnnouncementStatusUpdateDto} [announcementStatusUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAnnouncementIdStatusPut(id: number, announcementStatusUpdateDto?: AnnouncementStatusUpdateDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AnnouncementDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAnnouncementIdStatusPut(id, announcementStatusUpdateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAnnouncementLatestAnnouncementsAndNewsGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LatestAnnouncementsAndNewsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAnnouncementLatestAnnouncementsAndNewsGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} page 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAnnouncementPagePageGet(page: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AnnouncementDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAnnouncementPagePageGet(page, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {AnnouncementCreateDto} [announcementCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAnnouncementPost(announcementCreateDto?: AnnouncementCreateDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AnnouncementDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAnnouncementPost(announcementCreateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAnnouncementRecipientsNumberGet(id?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AnnouncementsRecipientsCountDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAnnouncementRecipientsNumberGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AnnouncementApi - factory interface
 * @export
 */
export const AnnouncementApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AnnouncementApiFp(configuration)
    return {
        /**
         * 
         * @param {number} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAnnouncementAnnouncementForEditGet(id?: number, options?: any): AxiosPromise<AnnouncementUpdateDto> {
            return localVarFp.apiAnnouncementAnnouncementForEditGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAnnouncementAnnouncementsForCmsGet(options?: any): AxiosPromise<Array<AnnouncementCmsDto>> {
            return localVarFp.apiAnnouncementAnnouncementsForCmsGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAnnouncementGet(options?: any): AxiosPromise<Array<AnnouncementDto>> {
            return localVarFp.apiAnnouncementGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAnnouncementIdDelete(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiAnnouncementIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAnnouncementIdGet(id: number, options?: any): AxiosPromise<AnnouncementDetailsDto> {
            return localVarFp.apiAnnouncementIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {AnnouncementUpdateDto} [announcementUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAnnouncementIdPut(id: number, announcementUpdateDto?: AnnouncementUpdateDto, options?: any): AxiosPromise<AnnouncementDetailsDto> {
            return localVarFp.apiAnnouncementIdPut(id, announcementUpdateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {AnnouncementStatusUpdateDto} [announcementStatusUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAnnouncementIdStatusPut(id: number, announcementStatusUpdateDto?: AnnouncementStatusUpdateDto, options?: any): AxiosPromise<AnnouncementDetailsDto> {
            return localVarFp.apiAnnouncementIdStatusPut(id, announcementStatusUpdateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAnnouncementLatestAnnouncementsAndNewsGet(options?: any): AxiosPromise<LatestAnnouncementsAndNewsDto> {
            return localVarFp.apiAnnouncementLatestAnnouncementsAndNewsGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} page 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAnnouncementPagePageGet(page: number, options?: any): AxiosPromise<Array<AnnouncementDto>> {
            return localVarFp.apiAnnouncementPagePageGet(page, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AnnouncementCreateDto} [announcementCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAnnouncementPost(announcementCreateDto?: AnnouncementCreateDto, options?: any): AxiosPromise<AnnouncementDetailsDto> {
            return localVarFp.apiAnnouncementPost(announcementCreateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAnnouncementRecipientsNumberGet(id?: number, options?: any): AxiosPromise<AnnouncementsRecipientsCountDto> {
            return localVarFp.apiAnnouncementRecipientsNumberGet(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AnnouncementApi - object-oriented interface
 * @export
 * @class AnnouncementApi
 * @extends {BaseAPI}
 */
export class AnnouncementApi extends BaseAPI {
    /**
     * 
     * @param {number} [id] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnnouncementApi
     */
    public apiAnnouncementAnnouncementForEditGet(id?: number, options?: any) {
        return AnnouncementApiFp(this.configuration).apiAnnouncementAnnouncementForEditGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnnouncementApi
     */
    public apiAnnouncementAnnouncementsForCmsGet(options?: any) {
        return AnnouncementApiFp(this.configuration).apiAnnouncementAnnouncementsForCmsGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnnouncementApi
     */
    public apiAnnouncementGet(options?: any) {
        return AnnouncementApiFp(this.configuration).apiAnnouncementGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnnouncementApi
     */
    public apiAnnouncementIdDelete(id: number, options?: any) {
        return AnnouncementApiFp(this.configuration).apiAnnouncementIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnnouncementApi
     */
    public apiAnnouncementIdGet(id: number, options?: any) {
        return AnnouncementApiFp(this.configuration).apiAnnouncementIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {AnnouncementUpdateDto} [announcementUpdateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnnouncementApi
     */
    public apiAnnouncementIdPut(id: number, announcementUpdateDto?: AnnouncementUpdateDto, options?: any) {
        return AnnouncementApiFp(this.configuration).apiAnnouncementIdPut(id, announcementUpdateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {AnnouncementStatusUpdateDto} [announcementStatusUpdateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnnouncementApi
     */
    public apiAnnouncementIdStatusPut(id: number, announcementStatusUpdateDto?: AnnouncementStatusUpdateDto, options?: any) {
        return AnnouncementApiFp(this.configuration).apiAnnouncementIdStatusPut(id, announcementStatusUpdateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnnouncementApi
     */
    public apiAnnouncementLatestAnnouncementsAndNewsGet(options?: any) {
        return AnnouncementApiFp(this.configuration).apiAnnouncementLatestAnnouncementsAndNewsGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} page 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnnouncementApi
     */
    public apiAnnouncementPagePageGet(page: number, options?: any) {
        return AnnouncementApiFp(this.configuration).apiAnnouncementPagePageGet(page, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AnnouncementCreateDto} [announcementCreateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnnouncementApi
     */
    public apiAnnouncementPost(announcementCreateDto?: AnnouncementCreateDto, options?: any) {
        return AnnouncementApiFp(this.configuration).apiAnnouncementPost(announcementCreateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [id] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnnouncementApi
     */
    public apiAnnouncementRecipientsNumberGet(id?: number, options?: any) {
        return AnnouncementApiFp(this.configuration).apiAnnouncementRecipientsNumberGet(id, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AnnouncementImageApi - axios parameter creator
 * @export
 */
export const AnnouncementImageApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAnnouncementImageGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/AnnouncementImage`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAnnouncementImageIdDelete: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiAnnouncementImageIdDelete', 'id', id)
            const localVarPath = `/api/AnnouncementImage/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAnnouncementImageIdFileStreamGet: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiAnnouncementImageIdFileStreamGet', 'id', id)
            const localVarPath = `/api/AnnouncementImage/{id}/FileStream`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAnnouncementImageIdGet: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiAnnouncementImageIdGet', 'id', id)
            const localVarPath = `/api/AnnouncementImage/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {AnnouncementImageUpdateDto} [announcementImageUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAnnouncementImageIdPut: async (id: number, announcementImageUpdateDto?: AnnouncementImageUpdateDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiAnnouncementImageIdPut', 'id', id)
            const localVarPath = `/api/AnnouncementImage/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(announcementImageUpdateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {any} [file] 
         * @param {string} [fileName] 
         * @param {string} [contentType] 
         * @param {string} [blobName] 
         * @param {number} [id] 
         * @param {string} [createdAt] 
         * @param {number} [createdById] 
         * @param {string} [updatedAt] 
         * @param {number} [updatedById] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAnnouncementImagePost: async (file?: any, fileName?: string, contentType?: string, blobName?: string, id?: number, createdAt?: string, createdById?: number, updatedAt?: string, updatedById?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/AnnouncementImage`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


            if (file !== undefined) { 
                localVarFormParams.append('File', file as any);
            }
    
            if (fileName !== undefined) { 
                localVarFormParams.append('FileName', fileName as any);
            }
    
            if (contentType !== undefined) { 
                localVarFormParams.append('ContentType', contentType as any);
            }
    
            if (blobName !== undefined) { 
                localVarFormParams.append('BlobName', blobName as any);
            }
    
            if (id !== undefined) { 
                localVarFormParams.append('Id', id as any);
            }
    
            if (createdAt !== undefined) { 
                localVarFormParams.append('CreatedAt', createdAt as any);
            }
    
            if (createdById !== undefined) { 
                localVarFormParams.append('CreatedById', createdById as any);
            }
    
            if (updatedAt !== undefined) { 
                localVarFormParams.append('UpdatedAt', updatedAt as any);
            }
    
            if (updatedById !== undefined) { 
                localVarFormParams.append('UpdatedById', updatedById as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AnnouncementImageApi - functional programming interface
 * @export
 */
export const AnnouncementImageApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AnnouncementImageApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAnnouncementImageGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AnnouncementImageDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAnnouncementImageGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAnnouncementImageIdDelete(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAnnouncementImageIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAnnouncementImageIdFileStreamGet(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAnnouncementImageIdFileStreamGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAnnouncementImageIdGet(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AnnouncementImageDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAnnouncementImageIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {AnnouncementImageUpdateDto} [announcementImageUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAnnouncementImageIdPut(id: number, announcementImageUpdateDto?: AnnouncementImageUpdateDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AnnouncementImageDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAnnouncementImageIdPut(id, announcementImageUpdateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {any} [file] 
         * @param {string} [fileName] 
         * @param {string} [contentType] 
         * @param {string} [blobName] 
         * @param {number} [id] 
         * @param {string} [createdAt] 
         * @param {number} [createdById] 
         * @param {string} [updatedAt] 
         * @param {number} [updatedById] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAnnouncementImagePost(file?: any, fileName?: string, contentType?: string, blobName?: string, id?: number, createdAt?: string, createdById?: number, updatedAt?: string, updatedById?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AnnouncementImageDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAnnouncementImagePost(file, fileName, contentType, blobName, id, createdAt, createdById, updatedAt, updatedById, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AnnouncementImageApi - factory interface
 * @export
 */
export const AnnouncementImageApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AnnouncementImageApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAnnouncementImageGet(options?: any): AxiosPromise<Array<AnnouncementImageDto>> {
            return localVarFp.apiAnnouncementImageGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAnnouncementImageIdDelete(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiAnnouncementImageIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAnnouncementImageIdFileStreamGet(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiAnnouncementImageIdFileStreamGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAnnouncementImageIdGet(id: number, options?: any): AxiosPromise<AnnouncementImageDetailsDto> {
            return localVarFp.apiAnnouncementImageIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {AnnouncementImageUpdateDto} [announcementImageUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAnnouncementImageIdPut(id: number, announcementImageUpdateDto?: AnnouncementImageUpdateDto, options?: any): AxiosPromise<AnnouncementImageDetailsDto> {
            return localVarFp.apiAnnouncementImageIdPut(id, announcementImageUpdateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {any} [file] 
         * @param {string} [fileName] 
         * @param {string} [contentType] 
         * @param {string} [blobName] 
         * @param {number} [id] 
         * @param {string} [createdAt] 
         * @param {number} [createdById] 
         * @param {string} [updatedAt] 
         * @param {number} [updatedById] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAnnouncementImagePost(file?: any, fileName?: string, contentType?: string, blobName?: string, id?: number, createdAt?: string, createdById?: number, updatedAt?: string, updatedById?: number, options?: any): AxiosPromise<AnnouncementImageDetailsDto> {
            return localVarFp.apiAnnouncementImagePost(file, fileName, contentType, blobName, id, createdAt, createdById, updatedAt, updatedById, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AnnouncementImageApi - object-oriented interface
 * @export
 * @class AnnouncementImageApi
 * @extends {BaseAPI}
 */
export class AnnouncementImageApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnnouncementImageApi
     */
    public apiAnnouncementImageGet(options?: any) {
        return AnnouncementImageApiFp(this.configuration).apiAnnouncementImageGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnnouncementImageApi
     */
    public apiAnnouncementImageIdDelete(id: number, options?: any) {
        return AnnouncementImageApiFp(this.configuration).apiAnnouncementImageIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnnouncementImageApi
     */
    public apiAnnouncementImageIdFileStreamGet(id: number, options?: any) {
        return AnnouncementImageApiFp(this.configuration).apiAnnouncementImageIdFileStreamGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnnouncementImageApi
     */
    public apiAnnouncementImageIdGet(id: number, options?: any) {
        return AnnouncementImageApiFp(this.configuration).apiAnnouncementImageIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {AnnouncementImageUpdateDto} [announcementImageUpdateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnnouncementImageApi
     */
    public apiAnnouncementImageIdPut(id: number, announcementImageUpdateDto?: AnnouncementImageUpdateDto, options?: any) {
        return AnnouncementImageApiFp(this.configuration).apiAnnouncementImageIdPut(id, announcementImageUpdateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {any} [file] 
     * @param {string} [fileName] 
     * @param {string} [contentType] 
     * @param {string} [blobName] 
     * @param {number} [id] 
     * @param {string} [createdAt] 
     * @param {number} [createdById] 
     * @param {string} [updatedAt] 
     * @param {number} [updatedById] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnnouncementImageApi
     */
    public apiAnnouncementImagePost(file?: any, fileName?: string, contentType?: string, blobName?: string, id?: number, createdAt?: string, createdById?: number, updatedAt?: string, updatedById?: number, options?: any) {
        return AnnouncementImageApiFp(this.configuration).apiAnnouncementImagePost(file, fileName, contentType, blobName, id, createdAt, createdById, updatedAt, updatedById, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ApplicationUserApi - axios parameter creator
 * @export
 */
export const ApplicationUserApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {Array<number>} [requestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationUserCategoriesPut: async (requestBody?: Array<number>, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/ApplicationUser/categories`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<number>} [requestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationUserDistrictsPut: async (requestBody?: Array<number>, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/ApplicationUser/districts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationUserEconomicActivityPut: async (body?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/ApplicationUser/economic-activity`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<FormOfTransport>} [formOfTransport] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationUserFormsOfTransportPut: async (formOfTransport?: Array<FormOfTransport>, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/ApplicationUser/forms-of-transport`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(formOfTransport, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationUserGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/ApplicationUser`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {boolean} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationUserHasChildrenPut: async (body?: boolean, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/ApplicationUser/has-children`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {boolean} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationUserLivesInKatowicePut: async (body?: boolean, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/ApplicationUser/lives-in-katowice`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [categoryId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationUserRemoveCategoryPatch: async (categoryId?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/ApplicationUser/removeCategory`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            if (categoryId !== undefined) {
                localVarQueryParameter['categoryId'] = categoryId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationUserTypeOfAccommodationPut: async (body?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/ApplicationUser/type-of-accommodation`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationUserUserDetailsGet: async (id?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/ApplicationUser/userDetails`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationUserUsersGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/ApplicationUser/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ApplicationUserApi - functional programming interface
 * @export
 */
export const ApplicationUserApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ApplicationUserApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {Array<number>} [requestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiApplicationUserCategoriesPut(requestBody?: Array<number>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationUserDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiApplicationUserCategoriesPut(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {Array<number>} [requestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiApplicationUserDistrictsPut(requestBody?: Array<number>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationUserDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiApplicationUserDistrictsPut(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiApplicationUserEconomicActivityPut(body?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationUserDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiApplicationUserEconomicActivityPut(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {Array<FormOfTransport>} [formOfTransport] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiApplicationUserFormsOfTransportPut(formOfTransport?: Array<FormOfTransport>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationUserDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiApplicationUserFormsOfTransportPut(formOfTransport, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiApplicationUserGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationUserDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiApplicationUserGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {boolean} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiApplicationUserHasChildrenPut(body?: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationUserDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiApplicationUserHasChildrenPut(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {boolean} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiApplicationUserLivesInKatowicePut(body?: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationUserDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiApplicationUserLivesInKatowicePut(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [categoryId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiApplicationUserRemoveCategoryPatch(categoryId?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationUserDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiApplicationUserRemoveCategoryPatch(categoryId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiApplicationUserTypeOfAccommodationPut(body?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationUserDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiApplicationUserTypeOfAccommodationPut(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiApplicationUserUserDetailsGet(id?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationUserDetailsCmsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiApplicationUserUserDetailsGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiApplicationUserUsersGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ApplicationUserDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiApplicationUserUsersGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ApplicationUserApi - factory interface
 * @export
 */
export const ApplicationUserApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ApplicationUserApiFp(configuration)
    return {
        /**
         * 
         * @param {Array<number>} [requestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationUserCategoriesPut(requestBody?: Array<number>, options?: any): AxiosPromise<ApplicationUserDetailsDto> {
            return localVarFp.apiApplicationUserCategoriesPut(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Array<number>} [requestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationUserDistrictsPut(requestBody?: Array<number>, options?: any): AxiosPromise<ApplicationUserDetailsDto> {
            return localVarFp.apiApplicationUserDistrictsPut(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationUserEconomicActivityPut(body?: string, options?: any): AxiosPromise<ApplicationUserDetailsDto> {
            return localVarFp.apiApplicationUserEconomicActivityPut(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Array<FormOfTransport>} [formOfTransport] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationUserFormsOfTransportPut(formOfTransport?: Array<FormOfTransport>, options?: any): AxiosPromise<ApplicationUserDetailsDto> {
            return localVarFp.apiApplicationUserFormsOfTransportPut(formOfTransport, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationUserGet(options?: any): AxiosPromise<ApplicationUserDetailsDto> {
            return localVarFp.apiApplicationUserGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {boolean} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationUserHasChildrenPut(body?: boolean, options?: any): AxiosPromise<ApplicationUserDetailsDto> {
            return localVarFp.apiApplicationUserHasChildrenPut(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {boolean} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationUserLivesInKatowicePut(body?: boolean, options?: any): AxiosPromise<ApplicationUserDetailsDto> {
            return localVarFp.apiApplicationUserLivesInKatowicePut(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [categoryId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationUserRemoveCategoryPatch(categoryId?: number, options?: any): AxiosPromise<ApplicationUserDetailsDto> {
            return localVarFp.apiApplicationUserRemoveCategoryPatch(categoryId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationUserTypeOfAccommodationPut(body?: string, options?: any): AxiosPromise<ApplicationUserDetailsDto> {
            return localVarFp.apiApplicationUserTypeOfAccommodationPut(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationUserUserDetailsGet(id?: number, options?: any): AxiosPromise<ApplicationUserDetailsCmsDto> {
            return localVarFp.apiApplicationUserUserDetailsGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationUserUsersGet(options?: any): AxiosPromise<Array<ApplicationUserDto>> {
            return localVarFp.apiApplicationUserUsersGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ApplicationUserApi - object-oriented interface
 * @export
 * @class ApplicationUserApi
 * @extends {BaseAPI}
 */
export class ApplicationUserApi extends BaseAPI {
    /**
     * 
     * @param {Array<number>} [requestBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationUserApi
     */
    public apiApplicationUserCategoriesPut(requestBody?: Array<number>, options?: any) {
        return ApplicationUserApiFp(this.configuration).apiApplicationUserCategoriesPut(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Array<number>} [requestBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationUserApi
     */
    public apiApplicationUserDistrictsPut(requestBody?: Array<number>, options?: any) {
        return ApplicationUserApiFp(this.configuration).apiApplicationUserDistrictsPut(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationUserApi
     */
    public apiApplicationUserEconomicActivityPut(body?: string, options?: any) {
        return ApplicationUserApiFp(this.configuration).apiApplicationUserEconomicActivityPut(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Array<FormOfTransport>} [formOfTransport] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationUserApi
     */
    public apiApplicationUserFormsOfTransportPut(formOfTransport?: Array<FormOfTransport>, options?: any) {
        return ApplicationUserApiFp(this.configuration).apiApplicationUserFormsOfTransportPut(formOfTransport, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationUserApi
     */
    public apiApplicationUserGet(options?: any) {
        return ApplicationUserApiFp(this.configuration).apiApplicationUserGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {boolean} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationUserApi
     */
    public apiApplicationUserHasChildrenPut(body?: boolean, options?: any) {
        return ApplicationUserApiFp(this.configuration).apiApplicationUserHasChildrenPut(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {boolean} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationUserApi
     */
    public apiApplicationUserLivesInKatowicePut(body?: boolean, options?: any) {
        return ApplicationUserApiFp(this.configuration).apiApplicationUserLivesInKatowicePut(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [categoryId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationUserApi
     */
    public apiApplicationUserRemoveCategoryPatch(categoryId?: number, options?: any) {
        return ApplicationUserApiFp(this.configuration).apiApplicationUserRemoveCategoryPatch(categoryId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationUserApi
     */
    public apiApplicationUserTypeOfAccommodationPut(body?: string, options?: any) {
        return ApplicationUserApiFp(this.configuration).apiApplicationUserTypeOfAccommodationPut(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [id] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationUserApi
     */
    public apiApplicationUserUserDetailsGet(id?: number, options?: any) {
        return ApplicationUserApiFp(this.configuration).apiApplicationUserUserDetailsGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationUserApi
     */
    public apiApplicationUserUsersGet(options?: any) {
        return ApplicationUserApiFp(this.configuration).apiApplicationUserUsersGet(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CategoryApi - axios parameter creator
 * @export
 */
export const CategoryApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCategoryGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Category`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCategoryIdDelete: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiCategoryIdDelete', 'id', id)
            const localVarPath = `/api/Category/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCategoryIdGet: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiCategoryIdGet', 'id', id)
            const localVarPath = `/api/Category/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {CategoryUpdateDto} [categoryUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCategoryIdPut: async (id: number, categoryUpdateDto?: CategoryUpdateDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiCategoryIdPut', 'id', id)
            const localVarPath = `/api/Category/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(categoryUpdateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CategoryCreateDto} [categoryCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCategoryPost: async (categoryCreateDto?: CategoryCreateDto, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Category`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(categoryCreateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CategoryApi - functional programming interface
 * @export
 */
export const CategoryApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CategoryApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCategoryGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CategoryDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCategoryGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCategoryIdDelete(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCategoryIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCategoryIdGet(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CategoryDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCategoryIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {CategoryUpdateDto} [categoryUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCategoryIdPut(id: number, categoryUpdateDto?: CategoryUpdateDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CategoryDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCategoryIdPut(id, categoryUpdateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CategoryCreateDto} [categoryCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCategoryPost(categoryCreateDto?: CategoryCreateDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CategoryDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCategoryPost(categoryCreateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CategoryApi - factory interface
 * @export
 */
export const CategoryApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CategoryApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCategoryGet(options?: any): AxiosPromise<Array<CategoryDto>> {
            return localVarFp.apiCategoryGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCategoryIdDelete(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiCategoryIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCategoryIdGet(id: number, options?: any): AxiosPromise<CategoryDetailsDto> {
            return localVarFp.apiCategoryIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {CategoryUpdateDto} [categoryUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCategoryIdPut(id: number, categoryUpdateDto?: CategoryUpdateDto, options?: any): AxiosPromise<CategoryDetailsDto> {
            return localVarFp.apiCategoryIdPut(id, categoryUpdateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CategoryCreateDto} [categoryCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCategoryPost(categoryCreateDto?: CategoryCreateDto, options?: any): AxiosPromise<CategoryDetailsDto> {
            return localVarFp.apiCategoryPost(categoryCreateDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CategoryApi - object-oriented interface
 * @export
 * @class CategoryApi
 * @extends {BaseAPI}
 */
export class CategoryApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CategoryApi
     */
    public apiCategoryGet(options?: any) {
        return CategoryApiFp(this.configuration).apiCategoryGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CategoryApi
     */
    public apiCategoryIdDelete(id: number, options?: any) {
        return CategoryApiFp(this.configuration).apiCategoryIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CategoryApi
     */
    public apiCategoryIdGet(id: number, options?: any) {
        return CategoryApiFp(this.configuration).apiCategoryIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {CategoryUpdateDto} [categoryUpdateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CategoryApi
     */
    public apiCategoryIdPut(id: number, categoryUpdateDto?: CategoryUpdateDto, options?: any) {
        return CategoryApiFp(this.configuration).apiCategoryIdPut(id, categoryUpdateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CategoryCreateDto} [categoryCreateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CategoryApi
     */
    public apiCategoryPost(categoryCreateDto?: CategoryCreateDto, options?: any) {
        return CategoryApiFp(this.configuration).apiCategoryPost(categoryCreateDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DistrictApi - axios parameter creator
 * @export
 */
export const DistrictApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDistrictGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/District`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDistrictIdDelete: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiDistrictIdDelete', 'id', id)
            const localVarPath = `/api/District/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDistrictIdGet: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiDistrictIdGet', 'id', id)
            const localVarPath = `/api/District/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {DistrictUpdateDto} [districtUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDistrictIdPut: async (id: number, districtUpdateDto?: DistrictUpdateDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiDistrictIdPut', 'id', id)
            const localVarPath = `/api/District/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(districtUpdateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DistrictCreateDto} [districtCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDistrictPost: async (districtCreateDto?: DistrictCreateDto, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/District`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(districtCreateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DistrictApi - functional programming interface
 * @export
 */
export const DistrictApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DistrictApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDistrictGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DistrictDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDistrictGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDistrictIdDelete(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDistrictIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDistrictIdGet(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DistrictDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDistrictIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {DistrictUpdateDto} [districtUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDistrictIdPut(id: number, districtUpdateDto?: DistrictUpdateDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DistrictDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDistrictIdPut(id, districtUpdateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {DistrictCreateDto} [districtCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDistrictPost(districtCreateDto?: DistrictCreateDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DistrictDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDistrictPost(districtCreateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DistrictApi - factory interface
 * @export
 */
export const DistrictApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DistrictApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDistrictGet(options?: any): AxiosPromise<Array<DistrictDto>> {
            return localVarFp.apiDistrictGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDistrictIdDelete(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiDistrictIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDistrictIdGet(id: number, options?: any): AxiosPromise<DistrictDetailsDto> {
            return localVarFp.apiDistrictIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {DistrictUpdateDto} [districtUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDistrictIdPut(id: number, districtUpdateDto?: DistrictUpdateDto, options?: any): AxiosPromise<DistrictDetailsDto> {
            return localVarFp.apiDistrictIdPut(id, districtUpdateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DistrictCreateDto} [districtCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDistrictPost(districtCreateDto?: DistrictCreateDto, options?: any): AxiosPromise<DistrictDetailsDto> {
            return localVarFp.apiDistrictPost(districtCreateDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DistrictApi - object-oriented interface
 * @export
 * @class DistrictApi
 * @extends {BaseAPI}
 */
export class DistrictApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DistrictApi
     */
    public apiDistrictGet(options?: any) {
        return DistrictApiFp(this.configuration).apiDistrictGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DistrictApi
     */
    public apiDistrictIdDelete(id: number, options?: any) {
        return DistrictApiFp(this.configuration).apiDistrictIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DistrictApi
     */
    public apiDistrictIdGet(id: number, options?: any) {
        return DistrictApiFp(this.configuration).apiDistrictIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {DistrictUpdateDto} [districtUpdateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DistrictApi
     */
    public apiDistrictIdPut(id: number, districtUpdateDto?: DistrictUpdateDto, options?: any) {
        return DistrictApiFp(this.configuration).apiDistrictIdPut(id, districtUpdateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DistrictCreateDto} [districtCreateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DistrictApi
     */
    public apiDistrictPost(districtCreateDto?: DistrictCreateDto, options?: any) {
        return DistrictApiFp(this.configuration).apiDistrictPost(districtCreateDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ImageApi - axios parameter creator
 * @export
 */
export const ImageApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiImageGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Image`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiImageIdDelete: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiImageIdDelete', 'id', id)
            const localVarPath = `/api/Image/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiImageIdFileImageGet: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiImageIdFileImageGet', 'id', id)
            const localVarPath = `/api/Image/{id}/FileImage`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiImageIdFileStreamGet: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiImageIdFileStreamGet', 'id', id)
            const localVarPath = `/api/Image/{id}/FileStream`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiImageIdGet: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiImageIdGet', 'id', id)
            const localVarPath = `/api/Image/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {ImageUpdateDto} [imageUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiImageIdPut: async (id: number, imageUpdateDto?: ImageUpdateDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiImageIdPut', 'id', id)
            const localVarPath = `/api/Image/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(imageUpdateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {any} [file] 
         * @param {string} [fileName] 
         * @param {string} [contentType] 
         * @param {string} [blobName] 
         * @param {number} [id] 
         * @param {string} [createdAt] 
         * @param {number} [createdById] 
         * @param {string} [updatedAt] 
         * @param {number} [updatedById] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiImagePost: async (file?: any, fileName?: string, contentType?: string, blobName?: string, id?: number, createdAt?: string, createdById?: number, updatedAt?: string, updatedById?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Image`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


            if (file !== undefined) { 
                localVarFormParams.append('File', file as any);
            }
    
            if (fileName !== undefined) { 
                localVarFormParams.append('FileName', fileName as any);
            }
    
            if (contentType !== undefined) { 
                localVarFormParams.append('ContentType', contentType as any);
            }
    
            if (blobName !== undefined) { 
                localVarFormParams.append('BlobName', blobName as any);
            }
    
            if (id !== undefined) { 
                localVarFormParams.append('Id', id as any);
            }
    
            if (createdAt !== undefined) { 
                localVarFormParams.append('CreatedAt', createdAt as any);
            }
    
            if (createdById !== undefined) { 
                localVarFormParams.append('CreatedById', createdById as any);
            }
    
            if (updatedAt !== undefined) { 
                localVarFormParams.append('UpdatedAt', updatedAt as any);
            }
    
            if (updatedById !== undefined) { 
                localVarFormParams.append('UpdatedById', updatedById as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ImageApi - functional programming interface
 * @export
 */
export const ImageApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ImageApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiImageGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ImageDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiImageGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiImageIdDelete(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiImageIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiImageIdFileImageGet(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiImageIdFileImageGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiImageIdFileStreamGet(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiImageIdFileStreamGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiImageIdGet(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ImageDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiImageIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {ImageUpdateDto} [imageUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiImageIdPut(id: number, imageUpdateDto?: ImageUpdateDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ImageDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiImageIdPut(id, imageUpdateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {any} [file] 
         * @param {string} [fileName] 
         * @param {string} [contentType] 
         * @param {string} [blobName] 
         * @param {number} [id] 
         * @param {string} [createdAt] 
         * @param {number} [createdById] 
         * @param {string} [updatedAt] 
         * @param {number} [updatedById] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiImagePost(file?: any, fileName?: string, contentType?: string, blobName?: string, id?: number, createdAt?: string, createdById?: number, updatedAt?: string, updatedById?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ImageDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiImagePost(file, fileName, contentType, blobName, id, createdAt, createdById, updatedAt, updatedById, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ImageApi - factory interface
 * @export
 */
export const ImageApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ImageApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiImageGet(options?: any): AxiosPromise<Array<ImageDto>> {
            return localVarFp.apiImageGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiImageIdDelete(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiImageIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiImageIdFileImageGet(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiImageIdFileImageGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiImageIdFileStreamGet(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiImageIdFileStreamGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiImageIdGet(id: number, options?: any): AxiosPromise<ImageDetailsDto> {
            return localVarFp.apiImageIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {ImageUpdateDto} [imageUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiImageIdPut(id: number, imageUpdateDto?: ImageUpdateDto, options?: any): AxiosPromise<ImageDetailsDto> {
            return localVarFp.apiImageIdPut(id, imageUpdateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {any} [file] 
         * @param {string} [fileName] 
         * @param {string} [contentType] 
         * @param {string} [blobName] 
         * @param {number} [id] 
         * @param {string} [createdAt] 
         * @param {number} [createdById] 
         * @param {string} [updatedAt] 
         * @param {number} [updatedById] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiImagePost(file?: any, fileName?: string, contentType?: string, blobName?: string, id?: number, createdAt?: string, createdById?: number, updatedAt?: string, updatedById?: number, options?: any): AxiosPromise<ImageDetailsDto> {
            return localVarFp.apiImagePost(file, fileName, contentType, blobName, id, createdAt, createdById, updatedAt, updatedById, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ImageApi - object-oriented interface
 * @export
 * @class ImageApi
 * @extends {BaseAPI}
 */
export class ImageApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ImageApi
     */
    public apiImageGet(options?: any) {
        return ImageApiFp(this.configuration).apiImageGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ImageApi
     */
    public apiImageIdDelete(id: number, options?: any) {
        return ImageApiFp(this.configuration).apiImageIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ImageApi
     */
    public apiImageIdFileImageGet(id: number, options?: any) {
        return ImageApiFp(this.configuration).apiImageIdFileImageGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ImageApi
     */
    public apiImageIdFileStreamGet(id: number, options?: any) {
        return ImageApiFp(this.configuration).apiImageIdFileStreamGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ImageApi
     */
    public apiImageIdGet(id: number, options?: any) {
        return ImageApiFp(this.configuration).apiImageIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {ImageUpdateDto} [imageUpdateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ImageApi
     */
    public apiImageIdPut(id: number, imageUpdateDto?: ImageUpdateDto, options?: any) {
        return ImageApiFp(this.configuration).apiImageIdPut(id, imageUpdateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {any} [file] 
     * @param {string} [fileName] 
     * @param {string} [contentType] 
     * @param {string} [blobName] 
     * @param {number} [id] 
     * @param {string} [createdAt] 
     * @param {number} [createdById] 
     * @param {string} [updatedAt] 
     * @param {number} [updatedById] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ImageApi
     */
    public apiImagePost(file?: any, fileName?: string, contentType?: string, blobName?: string, id?: number, createdAt?: string, createdById?: number, updatedAt?: string, updatedById?: number, options?: any) {
        return ImageApiFp(this.configuration).apiImagePost(file, fileName, contentType, blobName, id, createdAt, createdById, updatedAt, updatedById, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * NewsRssApi - axios parameter creator
 * @export
 */
export const NewsRssApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} category 
         * @param {number} page 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNewsRssCategoryPageGet: async (category: string, page: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'category' is not null or undefined
            assertParamExists('apiNewsRssCategoryPageGet', 'category', category)
            // verify required parameter 'page' is not null or undefined
            assertParamExists('apiNewsRssCategoryPageGet', 'page', page)
            const localVarPath = `/api/NewsRss/{category}/{page}`
                .replace(`{${"category"}}`, encodeURIComponent(String(category)))
                .replace(`{${"page"}}`, encodeURIComponent(String(page)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNewsRssGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/NewsRss`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNewsRssSummaryGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/NewsRss/summary`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * NewsRssApi - functional programming interface
 * @export
 */
export const NewsRssApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = NewsRssApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} category 
         * @param {number} page 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiNewsRssCategoryPageGet(category: string, page: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<NewsRssDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiNewsRssCategoryPageGet(category, page, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiNewsRssGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<NewsRssDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiNewsRssGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiNewsRssSummaryGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<NewsRssDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiNewsRssSummaryGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * NewsRssApi - factory interface
 * @export
 */
export const NewsRssApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = NewsRssApiFp(configuration)
    return {
        /**
         * 
         * @param {string} category 
         * @param {number} page 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNewsRssCategoryPageGet(category: string, page: number, options?: any): AxiosPromise<Array<NewsRssDto>> {
            return localVarFp.apiNewsRssCategoryPageGet(category, page, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNewsRssGet(options?: any): AxiosPromise<Array<NewsRssDto>> {
            return localVarFp.apiNewsRssGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNewsRssSummaryGet(options?: any): AxiosPromise<Array<NewsRssDto>> {
            return localVarFp.apiNewsRssSummaryGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * NewsRssApi - object-oriented interface
 * @export
 * @class NewsRssApi
 * @extends {BaseAPI}
 */
export class NewsRssApi extends BaseAPI {
    /**
     * 
     * @param {string} category 
     * @param {number} page 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NewsRssApi
     */
    public apiNewsRssCategoryPageGet(category: string, page: number, options?: any) {
        return NewsRssApiFp(this.configuration).apiNewsRssCategoryPageGet(category, page, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NewsRssApi
     */
    public apiNewsRssGet(options?: any) {
        return NewsRssApiFp(this.configuration).apiNewsRssGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NewsRssApi
     */
    public apiNewsRssSummaryGet(options?: any) {
        return NewsRssApiFp(this.configuration).apiNewsRssSummaryGet(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PinApi - axios parameter creator
 * @export
 */
export const PinApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} [announcementId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPinAnnouncementDelete: async (announcementId?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Pin/announcement`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            if (announcementId !== undefined) {
                localVarQueryParameter['announcementId'] = announcementId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [announcementId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPinAnnouncementPost: async (announcementId?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Pin/announcement`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            if (announcementId !== undefined) {
                localVarQueryParameter['announcementId'] = announcementId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPinGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Pin`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [newsGuid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPinNewsDelete: async (newsGuid?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Pin/news`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            if (newsGuid !== undefined) {
                localVarQueryParameter['newsGuid'] = newsGuid;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [newsGuid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPinNewsPost: async (newsGuid?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Pin/news`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            if (newsGuid !== undefined) {
                localVarQueryParameter['newsGuid'] = newsGuid;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PinApi - functional programming interface
 * @export
 */
export const PinApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PinApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} [announcementId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiPinAnnouncementDelete(announcementId?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LatestAnnouncementsAndNewsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiPinAnnouncementDelete(announcementId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [announcementId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiPinAnnouncementPost(announcementId?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LatestAnnouncementsAndNewsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiPinAnnouncementPost(announcementId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiPinGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LatestAnnouncementsAndNewsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiPinGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [newsGuid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiPinNewsDelete(newsGuid?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LatestAnnouncementsAndNewsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiPinNewsDelete(newsGuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [newsGuid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiPinNewsPost(newsGuid?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LatestAnnouncementsAndNewsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiPinNewsPost(newsGuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PinApi - factory interface
 * @export
 */
export const PinApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PinApiFp(configuration)
    return {
        /**
         * 
         * @param {number} [announcementId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPinAnnouncementDelete(announcementId?: number, options?: any): AxiosPromise<LatestAnnouncementsAndNewsDto> {
            return localVarFp.apiPinAnnouncementDelete(announcementId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [announcementId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPinAnnouncementPost(announcementId?: number, options?: any): AxiosPromise<LatestAnnouncementsAndNewsDto> {
            return localVarFp.apiPinAnnouncementPost(announcementId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPinGet(options?: any): AxiosPromise<LatestAnnouncementsAndNewsDto> {
            return localVarFp.apiPinGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [newsGuid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPinNewsDelete(newsGuid?: string, options?: any): AxiosPromise<LatestAnnouncementsAndNewsDto> {
            return localVarFp.apiPinNewsDelete(newsGuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [newsGuid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPinNewsPost(newsGuid?: string, options?: any): AxiosPromise<LatestAnnouncementsAndNewsDto> {
            return localVarFp.apiPinNewsPost(newsGuid, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PinApi - object-oriented interface
 * @export
 * @class PinApi
 * @extends {BaseAPI}
 */
export class PinApi extends BaseAPI {
    /**
     * 
     * @param {number} [announcementId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PinApi
     */
    public apiPinAnnouncementDelete(announcementId?: number, options?: any) {
        return PinApiFp(this.configuration).apiPinAnnouncementDelete(announcementId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [announcementId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PinApi
     */
    public apiPinAnnouncementPost(announcementId?: number, options?: any) {
        return PinApiFp(this.configuration).apiPinAnnouncementPost(announcementId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PinApi
     */
    public apiPinGet(options?: any) {
        return PinApiFp(this.configuration).apiPinGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [newsGuid] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PinApi
     */
    public apiPinNewsDelete(newsGuid?: string, options?: any) {
        return PinApiFp(this.configuration).apiPinNewsDelete(newsGuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [newsGuid] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PinApi
     */
    public apiPinNewsPost(newsGuid?: string, options?: any) {
        return PinApiFp(this.configuration).apiPinNewsPost(newsGuid, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * StreetApi - axios parameter creator
 * @export
 */
export const StreetApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStreetGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Street`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStreetIdDelete: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiStreetIdDelete', 'id', id)
            const localVarPath = `/api/Street/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStreetIdGet: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiStreetIdGet', 'id', id)
            const localVarPath = `/api/Street/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {StreetUpdateDto} [streetUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStreetIdPut: async (id: number, streetUpdateDto?: StreetUpdateDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiStreetIdPut', 'id', id)
            const localVarPath = `/api/Street/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(streetUpdateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {StreetCreateDto} [streetCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStreetPost: async (streetCreateDto?: StreetCreateDto, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Street`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(streetCreateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * StreetApi - functional programming interface
 * @export
 */
export const StreetApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = StreetApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiStreetGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<StreetDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiStreetGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiStreetIdDelete(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiStreetIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiStreetIdGet(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StreetDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiStreetIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {StreetUpdateDto} [streetUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiStreetIdPut(id: number, streetUpdateDto?: StreetUpdateDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StreetDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiStreetIdPut(id, streetUpdateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {StreetCreateDto} [streetCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiStreetPost(streetCreateDto?: StreetCreateDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StreetDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiStreetPost(streetCreateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * StreetApi - factory interface
 * @export
 */
export const StreetApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = StreetApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStreetGet(options?: any): AxiosPromise<Array<StreetDto>> {
            return localVarFp.apiStreetGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStreetIdDelete(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiStreetIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStreetIdGet(id: number, options?: any): AxiosPromise<StreetDetailsDto> {
            return localVarFp.apiStreetIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {StreetUpdateDto} [streetUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStreetIdPut(id: number, streetUpdateDto?: StreetUpdateDto, options?: any): AxiosPromise<StreetDetailsDto> {
            return localVarFp.apiStreetIdPut(id, streetUpdateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {StreetCreateDto} [streetCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStreetPost(streetCreateDto?: StreetCreateDto, options?: any): AxiosPromise<StreetDetailsDto> {
            return localVarFp.apiStreetPost(streetCreateDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * StreetApi - object-oriented interface
 * @export
 * @class StreetApi
 * @extends {BaseAPI}
 */
export class StreetApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StreetApi
     */
    public apiStreetGet(options?: any) {
        return StreetApiFp(this.configuration).apiStreetGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StreetApi
     */
    public apiStreetIdDelete(id: number, options?: any) {
        return StreetApiFp(this.configuration).apiStreetIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StreetApi
     */
    public apiStreetIdGet(id: number, options?: any) {
        return StreetApiFp(this.configuration).apiStreetIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {StreetUpdateDto} [streetUpdateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StreetApi
     */
    public apiStreetIdPut(id: number, streetUpdateDto?: StreetUpdateDto, options?: any) {
        return StreetApiFp(this.configuration).apiStreetIdPut(id, streetUpdateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {StreetCreateDto} [streetCreateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StreetApi
     */
    public apiStreetPost(streetCreateDto?: StreetCreateDto, options?: any) {
        return StreetApiFp(this.configuration).apiStreetPost(streetCreateDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * WasteScheduleApi - axios parameter creator
 * @export
 */
export const WasteScheduleApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [street] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiWasteScheduleHomeNumbersGet: async (street?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/WasteSchedule/homeNumbers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            if (street !== undefined) {
                localVarQueryParameter['street'] = street;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [searchTerm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiWasteScheduleStreetsGet: async (searchTerm?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/WasteSchedule/streets`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            if (searchTerm !== undefined) {
                localVarQueryParameter['searchTerm'] = searchTerm;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [street] 
         * @param {string} [homeNumber] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiWasteScheduleWasteScheduleGet: async (street?: string, homeNumber?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/WasteSchedule/wasteSchedule`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            if (street !== undefined) {
                localVarQueryParameter['street'] = street;
            }

            if (homeNumber !== undefined) {
                localVarQueryParameter['homeNumber'] = homeNumber;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * WasteScheduleApi - functional programming interface
 * @export
 */
export const WasteScheduleApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = WasteScheduleApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} [street] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiWasteScheduleHomeNumbersGet(street?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<WasteHomeNumberDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiWasteScheduleHomeNumbersGet(street, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [searchTerm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiWasteScheduleStreetsGet(searchTerm?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<WasteStreetDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiWasteScheduleStreetsGet(searchTerm, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [street] 
         * @param {string} [homeNumber] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiWasteScheduleWasteScheduleGet(street?: string, homeNumber?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<WasteScheduleDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiWasteScheduleWasteScheduleGet(street, homeNumber, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * WasteScheduleApi - factory interface
 * @export
 */
export const WasteScheduleApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = WasteScheduleApiFp(configuration)
    return {
        /**
         * 
         * @param {string} [street] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiWasteScheduleHomeNumbersGet(street?: string, options?: any): AxiosPromise<Array<WasteHomeNumberDto>> {
            return localVarFp.apiWasteScheduleHomeNumbersGet(street, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [searchTerm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiWasteScheduleStreetsGet(searchTerm?: string, options?: any): AxiosPromise<Array<WasteStreetDto>> {
            return localVarFp.apiWasteScheduleStreetsGet(searchTerm, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [street] 
         * @param {string} [homeNumber] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiWasteScheduleWasteScheduleGet(street?: string, homeNumber?: string, options?: any): AxiosPromise<Array<WasteScheduleDto>> {
            return localVarFp.apiWasteScheduleWasteScheduleGet(street, homeNumber, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * WasteScheduleApi - object-oriented interface
 * @export
 * @class WasteScheduleApi
 * @extends {BaseAPI}
 */
export class WasteScheduleApi extends BaseAPI {
    /**
     * 
     * @param {string} [street] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WasteScheduleApi
     */
    public apiWasteScheduleHomeNumbersGet(street?: string, options?: any) {
        return WasteScheduleApiFp(this.configuration).apiWasteScheduleHomeNumbersGet(street, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [searchTerm] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WasteScheduleApi
     */
    public apiWasteScheduleStreetsGet(searchTerm?: string, options?: any) {
        return WasteScheduleApiFp(this.configuration).apiWasteScheduleStreetsGet(searchTerm, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [street] 
     * @param {string} [homeNumber] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WasteScheduleApi
     */
    public apiWasteScheduleWasteScheduleGet(street?: string, homeNumber?: string, options?: any) {
        return WasteScheduleApiFp(this.configuration).apiWasteScheduleWasteScheduleGet(street, homeNumber, options).then((request) => request(this.axios, this.basePath));
    }
}


