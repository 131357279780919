import axios, {AxiosError, AxiosResponse} from "axios";
import {injectable} from "inversify";
import "reflect-metadata";
import {Configuration} from "../api";
import {BaseAPI} from "../api/base";
import {message} from "antd";
import * as Sentry from "@sentry/react";

@injectable()
export default class ApiService {

    private displaying401Error: boolean = false;
    private displaying403Error: boolean = false;
    private displayingUnknownError: boolean = false;

    constructor(private accessToken: string) {
        const me = this;

        axios.interceptors.response.use(
            (response: AxiosResponse) => {
                // Any status code that lie within the range of 2xx cause this function to trigger
                // Do something with response data
                return response;
            },
            function (error: AxiosError) {
                if (error.response?.status === 403) {

                    if (!me.displaying403Error) {
                        me.displaying403Error = true;

                        message.warning(<>
                            <h3>Wygląda na to, że nie masz dostępu do żądanego zasobu.</h3>
                            <div>Spróbuj zalogować się ponownie. Jeśli to nie pomoże, skontaktuj się z
                                administratorem.
                            </div>
                        </>);

                        setTimeout(() => me.displaying403Error = false, 3000);
                    }
                } else if (error.response?.status !== 400 && error.response?.status !== 404 && error.message !== "canceled") {
                    if (!me.displayingUnknownError) {
                        me.displayingUnknownError = true;
                        Sentry.captureException(error);

                        message.error(<>
                            <h3>Wystąpił błąd</h3>
                            <div>Spróbuj ponownie. Jeżeli to nie pomoże, skontaktuj się z administratorem</div>
                        </>);

                        setTimeout(() => me.displayingUnknownError = false, 3000);
                    }
                }
                return Promise.reject(error);
            }
        );

        axios.defaults.withCredentials = true;
    }

    public getApi<T extends BaseAPI>(c: new (configuration?: Configuration, basePath?: string) => T): T {
        const apiUrl = process.env.REACT_APP_URL;

        return new c(new Configuration({accessToken: this.accessToken}), apiUrl);
    }
}
